import { Component, OnInit } from '@angular/core';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { RouterNavigation, RouterDataResolved } from '@ngxs/router-plugin';
import { SetLogin } from './core/store/auth/auth.actions';
import { SaveReturnUrl, SetIsMobile, SaveReturnUrlAfterLogin, SetPreviousUrl, SetCurrentContext, AddCurrentScrollableElement } from './core/store/layout/layout.actions';
import { environment } from 'apps/early-stage-office/src/environments/environment';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { filter, map } from 'rxjs/operators';
import { CSSBreakpoints } from './core/constans';
import { SiteContext, LayoutState } from './core/store/layout/layout.state';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { routerTransition } from './core/animations';
import { LoadPolls } from './core/store/dictionary/dictionary.actions';
import { TitleService } from './core/services/title.service';
import { CheckForUpdateService } from './core/services/check-for-update.service';
import { SetCurrentRoleByUrl } from './core/store/profile/profile.actions';
import { ConnectionIssuesBarComponent } from './shared/components/connection-issues-bar/connection-issues-bar.component';
import { SupportButtonComponent } from './shared/components/support-button/support-button.component';
import { NgIf } from '@angular/common';
import { TestBarComponent } from './shared/components/test-bar/test-bar.component';
import { setDefaultOptions } from 'date-fns';
import { pl } from 'date-fns/locale';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PrivacyConsentsModalsService, PrivacyConsentsService, ToastsContainerComponent } from '@early-stage/components';

@Component({
    selector: 'es-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routerTransition],
    standalone: true,
    imports: [TestBarComponent, RouterOutlet, NgIf, SupportButtonComponent, ConnectionIssuesBarComponent, ToastsContainerComponent],
})
export class AppComponent implements OnInit {
    public loading: boolean = true;
    public title: string = 'ES Office';
    public showSupportButton: boolean;
    public routerHistory: RouterNavigation[] = [];
    public isMobile: boolean = true;

    public get previousRouter(): RouterNavigation {
        return this.routerHistory[this.routerHistory.length - 1];
    }

    constructor(
        private actions: Actions,
        private store: Store,
        public breakpointObserver: BreakpointObserver,
        public router: Router,
        public route: ActivatedRoute,
        public privacyConsentsModals: PrivacyConsentsModalsService,
        public privacyConsents: PrivacyConsentsService,
        private titleService: TitleService,
        public checkForUpdates: CheckForUpdateService,
        private gtmService: GoogleTagManagerService
    ) {
        checkForUpdates.checkForUpdates();
    }

    public prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }

    public async ngOnInit() {
        this._manageConsents();

        setDefaultOptions({ locale: pl });

        this.titleService.setSufix(' • ES Office');

        this.store.dispatch(new LoadPolls());
        this.store.dispatch(new AddCurrentScrollableElement({ element: document }));

        this.breakpointObserver
            .observe([`(max-width: ${CSSBreakpoints.lg - 1}px)`])
            .pipe(map((state: BreakpointState) => state.matches))
            .subscribe(event => {
                this.store.dispatch(new SetIsMobile({ isMobile: event }));
                this.isMobile = event;
            });

        this.actions.pipe(ofActionSuccessful(SetLogin)).subscribe(() => {
            const returnUrl = this.store.selectSnapshot(LayoutState.returnUrlAfterLogin);
            this.store.dispatch(new SaveReturnUrlAfterLogin({ url: null }));

            const url = returnUrl ? returnUrl : '/';

            this.router.navigateByUrl(url);
        });

        this.actions.pipe(ofActionSuccessful(RouterNavigation)).subscribe((route: RouterNavigation) => {
            const currentPath = route.routerState.url.split('/')[1];
            let previousUrl = '';

            if (this.previousRouter) {
                const path = this.previousRouter.routerState.url;
                previousUrl = path;

                if (path.split('/')[1] !== 'edziennik' && currentPath === 'edziennik') {
                    const url = this.previousRouter.routerState.url;

                    this.store.dispatch(new SaveReturnUrl({ url }));
                }
            }

            this.store.dispatch(new SetPreviousUrl({ url: previousUrl }));
            this.store.dispatch(new SetCurrentRoleByUrl());
            this.routerHistory.push(route);

            const contextUrl = route.routerState.url.split('/')[1];

            const contexts = {
                superadmin: SiteContext.Superadmin,
                franczyza: SiteContext.Franchise,
                profil: SiteContext.Profile,
                metodyk: SiteContext.DOS,
                lektor: SiteContext.Lector,
                edziennik: SiteContext.ClassRegister,
                platnosci: SiteContext.Payments,
            };

            if (contexts[contextUrl]) {
                this.store.dispatch(new SetCurrentContext({ context: contexts[contextUrl] }));
            }

            setTimeout(() => {
                const data = this.getRouteData(this.route.snapshot);
                this.showSupportButton = data.forceSupportButton || false;
            });
        });

        this.actions.pipe(ofActionSuccessful(RouterDataResolved)).subscribe((route: RouterDataResolved) => {
            // wykluczanie modala ocen TODO:
            const url = route.event.url;
            if (url.indexOf('edziennik') && url.indexOf('ocena')) {
                return;
            }

            window.scrollTo(0, 0);
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            this.titleService.resolveTitle();
        });

        this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckStart) {
                this.loading = true;
            }
            if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                this.loading = false;
            }
        });
    }

    public getRouteData(route: ActivatedRouteSnapshot): Data {
        let params = {};

        if (route.params) {
            params = Object.assign(params, route.data);
        }

        for (const child of route.children) {
            params = Object.assign(params, this.getRouteData(child));
        }

        return params;
    }

    private async _manageConsents(): Promise<void> {
        if (environment.GTMTrackingId) {
            await this.gtmService.addGtmToDom();

            if (this.privacyConsents.areAllConsentSaved()) {
                this.privacyConsents.sendConsents();
            } else {
                await this.privacyConsentsModals.openConsentsInfoModalIfNeeded();
            }

            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    const gtmTag = {
                        event: 'page',
                        pageName: item.url,
                    };

                    this.gtmService.pushTag(gtmTag);
                }
            });

            this.gtmService.pushTag({
                event: 'app_initialization',
            });
        }
    }
}
